import React from "react";
import Navbar from "../components/navbar";
import {
  Jumbotron,
  JumbotronContent,
  JumbotronH2,
  SeparatorBar,
  JumbotronH1,
} from "../components/jumbotron";

const NotFoundPage = () => (
  <>
    <Navbar />
    <Jumbotron>
      <JumbotronContent>
        <div>
          <JumbotronH1>404</JumbotronH1>
          <JumbotronH2>Page non trouvée</JumbotronH2>
          <div className="mt-5">
            <a className="btn-action px-3 py-2" href="/">
              Retour
            </a>
          </div>
        </div>
      </JumbotronContent>
    </Jumbotron>
  </>
);

export default NotFoundPage;
