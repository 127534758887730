import * as React from "react";
import styled from "@emotion/styled";
import { withClassName } from "../styled-util";
import { useStaticQuery, graphql } from "gatsby";

export const SeparatorBar = styled(withClassName("separator"))`
  border-top: 2px solid white;
  width: 50px;
  margin: 40px auto;
`;

const JumbotronWrapper = styled(
  withClassName(
    "jumbotron d-flex flex-column justify-content-around rounded-0 p-0"
  )
)`
  background-image: url(${props => props.image || ""});
  background-position: center;
  background-size: cover;
  margin-bottom: 2rem;
`;

export const Jumbotron = props => {
  const data = useStaticQuery(graphql`
    query BannerImage {
      image: file(relativePath: { eq: "banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100, maxHeight: 400) {
            src
          }
        }
      }
    }
  `);
  return (
    <JumbotronWrapper
      {...props}
      image={props.image || data.image.childImageSharp.fluid.src}
    />
  );
};

export const JumbotronContent = styled(
  withClassName("d-flex flex-column justify-content-center")
)`
  min-height: 400px;
  background-color: ${props => props.backgroundColor || "rgba(0, 0, 0, 0.12)"};
  color: white;
  margin: 0 0 0 0;
  text-align: center;
  @media (min-width: 960px) {
    text-align: left;
    padding: 0 40px;
    .separator {
    }
  }
`;

export const JumbotronH1 = styled.h1`
  font-weight: 900;
  text-transform: uppercase;
  font-size: 42px !important;
  @media (max-width: 1140px) {
    font-size: 36px !important;
  }
`;

export const JumbotronH2 = styled.span`
  display: block;
  padding-top: 1rem;
  color: white;
  font-size: 24px !important;
  text-transform: none !important;
`;
